import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Input, Button, Form, Select, Card, message } from 'antd';
import { firestore, firebase } from '../../firebase/firebase'
import CircularProgress from "../CircularProgress/index";
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
const { Option } = Select
const { REACT_APP_PRODUCTION_MODE } = process.env;


const AddUser = (props) => {
  const { register } = useForm();
  const [loader, setLoading] = useState(false);

  const [form2] = Form.useForm();




  const validator = async (rule, value) => {
    if (rule.field === 'phone_number') {
      const querySnapshot = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_users' : 'users')
        .where("phone_number", '==', value)
        .get()
        if (!querySnapshot.empty) {
          return Promise.reject('phone number already exists');
        }
      else if (value && ! /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(value)) {
        return Promise.reject('Please enter only number'); 
      } else {
        return Promise.resolve();
      }
    }
    else if (rule.field === 'user_name' && value) {
      if (value.length < 5) {
        return Promise.reject('Username must be at least 5 characters long');
    }
      const querySnapshot = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_users' : 'users')
        .where("user_name", '==', value)
        .get()
      if (!querySnapshot.empty) {
        return Promise.reject('user name already exists');
      }
      else {
        return Promise.resolve();
      }

    } else if (rule.field === 'email' && value) {
      const querySnapshot = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_users' : 'users')
        .where("email", '==', value)
        .get()

      if (!querySnapshot.empty) {
        return Promise.reject('email already exists ');
      }
      else {
        return Promise.resolve();
      }
    }

    else if (rule.field === 'phone_number' && value) {
      const querySnapshot = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_users' : 'users')
        .where("phone_number", '==', value)
        .get()

      if (!querySnapshot.empty) {
        return Promise.reject('phone number already exists');
      } else {
        return Promise.resolve();
      }
    }
    if (rule.field === 'password') {
      if (value && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9]).{8,}$/.test(value)) {
        return Promise.reject('Password needs to be at least 8 characters, 1 lowercase letter, 1 uppercase letter, 1 number and 1 special character');
      } else {
        return Promise.resolve();
      }
    }
    else {
      return Promise.resolve();
    }
  }

  const countryName =
    [
      { [+213]: 'Algeria' },
      { [+376]: 'Andorra' },
      { [+244]: 'Angola' },
      { [+1264]: 'Anguilla' },
      { [+1268]: 'Antigua & Barbuda' },
      { [+54]: 'Argentina' },
      { [+374]: 'Armenia' },
      { [+297]: 'Aruba' },
      { [+61]: 'Australia' },
      { [+43]: 'Austria' },
      { [+994]: 'Azerbaijan' },
      { [+1242]: 'Bahamas' },
      { [+973]: 'Bahrain' },
      { [+880]: 'Bangladesh' },
      { [+1246]: 'Barbados' },
      { [+375]: 'Belarus' },
      { [+32]: 'Belgium' },
      { [+501]: 'Belize' },
      { [+229]: 'Benin' },
      { [+1441]: 'Bermuda' },
      { [+975]: 'Bhutan' },
      { [+591]: 'Bolivia' },
      { [+387]: 'Bosnia Herzegovina' },
      { [+267]: 'Botswana' },
      { [+55]: 'Brazil' },
      { [+673]: 'Brunei' },
      { [+359]: 'Bulgaria' },
      { [+226]: 'Burkina Faso' },
      { [+257]: 'Burundi' },
      { [+855]: 'Cambodia' },
      { [+237]: 'Cameroon' },
      { [+1]: 'Canada' },
      { [+238]: 'Cape Verde Islands' },
      { [+1345]: 'Cayman Islands' },
      { [+236]: 'Central African Republic' },
      { [+56]: 'Chile' },
      { [+86]: 'China' },
      { [+57]: 'Colombia' },
      { [+269]: 'Comoros' },
      { [+242]: 'Congo' },
      { [+682]: 'Cook Islands' },
      { [+506]: 'Costa Rica' },
      { [+385]: 'Croatia' },
      { [+53]: 'Cuba' },
      { [+90392]: 'Cyprus North' },
      { [+357]: 'Cyprus South' },
      { [+42]: 'Czech Republic' },
      { [+45]: 'Denmark' },
      { [+253]: 'Djibouti' },
      { [+1809]: 'Dominica & Dominican Republic' },
      { [+593]: 'Ecuador' },
      { [+20]: 'Egypt' },
      { [+503]: 'El Salvador' },
      { [+240]: 'Equatorial Guinea' },
      { [+291]: 'Eritrea' },
      { [+372]: 'Estonia' },
      { [+251]: 'Ethiopia' },
      { [+500]: 'Falkland Islands' },
      { [+298]: 'Faroe Islands' },
      { [+679]: 'Fiji' },
      { [+358]: 'Finland' },
      { [+33]: 'France' },
      { [+594]: 'French Guiana' },
      { [+689]: 'French Polynesia' },
      { [+241]: 'Gabon' },
      { [+220]: 'Gambia' },
      { [+7880]: 'Georgia' },
      { [+49]: 'Germany' },
      { [+233]: 'Ghana' },
      { [+350]: 'Gibraltar' },
      { [+30]: 'Greece' },
      { [+299]: 'Greenland' },
      { [+1473]: 'Grenada' },
      { [+590]: 'Guadeloupe' },
      { [+671]: 'Guam' },
      { [+502]: 'Guatemala' },
      { [+224]: 'Guinea' },
      { [+245]: 'Guinea - Bissau' },
      { [+592]: 'Guyana' },
      { [+509]: 'Haiti' },
      { [+504]: 'Honduras' },
      { [+852]: 'Hong Kong' },
      { [+36]: 'Hungary' },
      { [+354]: 'Iceland' },
      { [+91]: 'India' },
      { [+62]: 'Indonesia' },
      { [+98]: 'Iran' },
      { [+964]: 'Iraq' },
      { [+353]: 'Ireland' },
      { [+972]: 'Israel' },
      { [+39]: 'Italy' },
      { [+1876]: 'Jamaica' },
      { [+81]: 'Japan' },
      { [+962]: 'Jordan' },
      { [+7]: 'Kazakhstan' },
      { [+254]: 'Kenya' },
      { [+686]: 'Kiribati' },
      { [+850]: 'Korea North' },
      { [+82]: 'Korea South' },
      { [+383]: 'Kosovo' },
      { [+965]: 'Kuwait' },
      { [+996]: 'Kyrgyzstan' },
      { [+856]: 'Laos' },
      { [+371]: 'Latvia' },
      { [+961]: 'Lebanon' },
      { [+266]: 'Lesotho' },
      { [+231]: 'Liberia' },
      { [+218]: 'Libya' },
      { [+417]: 'Liechtenstein' },
      { [+370]: 'Lithuania' },
      { [+352]: 'Luxembourg' },
      { [+853]: 'Macao' },
      { [+389]: 'Macedonia' },
      { [+261]: 'Madagascar' },
      { [+265]: 'Malawi' },
      { [+60]: 'Malaysia' },
      { [+960]: 'Maldives' },
      { [+223]: 'Mali' },
      { [+356]: 'Malta' },
      { [+692]: 'Marshall Islands' },
      { [+596]: 'Martinique' },
      { [+222]: 'Mauritania' },
      { [+269]: 'Mayotte' },
      { [+52]: 'Mexico' },
      { [+691]: 'Micronesia' },
      { [+373]: 'Moldova' },
      { [+377]: 'Monaco' },
      { [+976]: 'Mongolia' },
      { [+1664]: 'Montserrat' },
      { [+212]: 'Morocco' },
      { [+258]: 'Mozambique' },
      { [+95]: 'Myanmar' },
      { [+264]: 'Namibia' },
      { [+674]: 'Nauru' },
      { [+977]: 'Nepal' },
      { [+31]: 'Netherlands' },
      { [+687]: 'New Caledonia' },
      { [+64]: 'New Zealand' },
      { [+505]: 'Nicaragua' },
      { [+227]: 'Niger' },
      { [+234]: 'Nigeria' },
      { [+683]: 'Niue' },
      { [+672]: 'Norfolk Islands' },
      { [+670]: 'Northern Marianas' },
      { [+47]: 'Norway' },
      { [+968]: 'Oman' },
      { [+680]: 'Palau' },
      { [+507]: 'Panama' },
      { [+675]: 'Papua New Guinea' },
      { [+595]: 'Paraguay' },
      { [+51]: 'Peru' },
      { [+63]: 'Philippines' },
      { [+48]: 'Poland' },
      { [+351]: 'Portugal' },
      { [+1787]: 'Puerto Rico' },
      { [+974]: 'Qatar' },
      { [+262]: 'Reunion' },
      { [+40]: 'Romania' },
      { [+7]: 'Russia' },
      { [+250]: 'Rwanda' },
      { [+378]: 'San Marino' },
      { [+239]: 'Sao Tome & Principe' },
      { [+966]: 'Saudi Arabia' },
      { [+221]: 'Senegal' },
      { [+381]: 'Serbia' },
      { [+248]: 'Seychelles' },
      { [+232]: 'Sierra Leone' },
      { [+65]: 'Singapore' },
      { [+421]: 'Slovak Republic' },
      { [+386]: 'Slovenia' },
      { [+677]: 'Solomon Islands' },
      { [+252]: 'Somalia' },
      { [+27]: 'South Africa' },
      { [+34]: 'Spain' },
      { [+94]: 'Sri Lanka' },
      { [+290]: 'St. Helena' },
      { [+1869]: 'St. Kitts' },
      { [+1758]: 'St. Lucia' },
      { [+249]: 'Sudan' },
      { [+597]: 'Suriname' },
      { [+268]: 'Swaziland' },
      { [+46]: 'Sweden' },
      { [+41]: 'Switzerland' },
      { [+963]: 'Syria' },
      { [+886]: 'Taiwan' },
      { [+7]: 'Tajikstan' },
      { [+66]: 'Thailand' },
      { [+228]: 'Togo' },
      { [+676]: 'Tonga' },
      { [+1868]: 'Trinidad & Tobago' },
      { [+216]: 'Tunisia' },
      { [+90]: 'Turkey' },
      { [+7]: 'Turkmenistan' },
      { [+993]: 'Turkmenistan' },
      { [+1649]: 'Turks & Caicos Islands' },
      { [+688]: 'Tuvalu' },
      { [+256]: 'Uganda' },
      { [+44]: 'UK' },
      { [+380]: 'Ukraine' },
      { [+971]: 'United Arab Emirates' },
      { [+598]: 'Uruguay' },
      { [+1]: 'USA' },
      { [+7]: 'Uzbekistan' },
      { [+678]: 'Vanuatu' },
      { [+379]: 'Vatican City' },
      { [+58]: 'Venezuela' },
      { [+84]: 'Vietnam' },
      { [+1284]: 'Virgin Islands - British' },
      { [+1340]: 'Virgin Islands - US' },
      { [+681]: 'Wallis & Futuna' },
      { [+969]: 'Yemen' },
      { [+967]: 'Yemen' },
      { [+260]: 'Zambia' },
      { [+263]: 'Zimbabwe' },
    ]

  const onSubmit = async (values) => {
    try {
      setLoading(true)
      // get a reference to the Firestore document
      const docRef = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_users' : 'users').doc().id
      let country_name = ''
      countryName.map((countryData) => {
        for (const key in countryData) {
          if (key == parseInt(values.country_code)) {
            country_name = countryData[key]
          }
        }
      })
      // create user object
      const userProfile = {
        email: values.email,
        full_name: `${values.first_name} ${values.last_name}`,
        phone_number: values.phone_number,
        country_code: values.country_code,
        country_name: country_name,
        user_name: values.user_name,
        password: values.password,
        language: "en",
        bio: "",
        gender: "",
        birthday: "",
        status: 'approved',
        profile_url: '',
        following: [],
        followers: [],
        posts: [],
        exhibitions: [],
        facebook_id: "",
        is_deleted: false,
        artwork_status: false,
        subscription_plan: 'Standard',
        exhibition_point: 0,
        user_id: docRef,
        online: false,
        stripe_customer_id: '',
        stripe_subscription_id: '',
        payment_method_id: '',
        zoho_contact_id: '',
        create_at: firebase.firestore.FieldValue.serverTimestamp(),
        update_at: firebase.firestore.FieldValue.serverTimestamp(),
        usercreationdate : moment(new Date(), "MMMM D, YYYY [at] h:mm:ss A [UTC]Z").valueOf(),
      };
      // write to Cloud Firestore
      const token = uuidv4();
      await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_users' : 'users').doc(docRef).set(userProfile)
      const deviceDataCheck = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_device_data' : 'device_data').doc(docRef);
      deviceDataCheck.set({
        create_at: firebase.firestore.FieldValue.serverTimestamp(),
            update_at: firebase.firestore.FieldValue.serverTimestamp(),
            device_id: token,
          user_id: docRef
      })
      await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_notification' : "notification").doc(docRef).set({
        follow: true,
        post: true,
        message: true
      })

      setTimeout(() => {
        setLoading(false)
        message.success('Successfully Registered');
        return  props.history.push({
          pathname: `/usersdetails`,
        });
      }, 2000);
     
    } catch (error) {
      setLoading(false)
      message.error('Something went to wrong');
    }
  };

  const getPhonenumber = async (values) => {
    const phoneNumber = values.phone_number
    const result = await firestore
      .collection('users')
      .where('phone_number', '==', phoneNumber)
      .get();

    return result.docs.map(async (item) => {
      const docId = item.id
      await firestore
        .collection('users').doc(docId).update({ user_id: docId })
    });
  }
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };


  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };
  function onSearch() {

  }
  const prefixSelector = (
    <Form.Item name="country_code" noStyle>
      <Select showSearch placeholder="Select a Country Code" style={{ width: 200 }} onSearch={onSearch} filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }>
        <Option value="+213">Algeria (+213)</Option>
        <Option value="+376">Andorra (+376)</Option>
        <Option value="+244">Angola (+244)</Option>
        <Option value="+1264">Anguilla (+1264)</Option>
        <Option value="+1268">Antigua &amp; Barbuda (+1268)</Option>
        <Option value="+54">Argentina (+54)</Option>
        <Option value="+374">Armenia (+374)</Option>
        <Option value="+297">Aruba (+297)</Option>
        <Option value="+61">Australia (+61)</Option>
        <Option value="+43">Austria (+43)</Option>
        <Option value="+994">Azerbaijan (+994)</Option>
        <Option value="+1242">Bahamas (+1242)</Option>
        <Option value="+973">Bahrain (+973)</Option>
        <Option value="+880">Bangladesh (+880)</Option>
        <Option value="+1246">Barbados (+1246)</Option>
        <Option value="+375">Belarus (+375)</Option>
        <Option value="+32">Belgium (+32)</Option>
        <Option value="+501">Belize (+501)</Option>
        <Option value="+229">Benin (+229)</Option>
        <Option value="+1441">Bermuda (+1441)</Option>
        <Option value="+975">Bhutan (+975)</Option>
        <Option value="+591">Bolivia (+591)</Option>
        <Option value="+387">Bosnia Herzegovina (+387)</Option>
        <Option value="+267">Botswana (+267)</Option>
        <Option value="+55">Brazil (+55)</Option>
        <Option value="+673">Brunei (+673)</Option>
        <Option value="+359">Bulgaria (+359)</Option>
        <Option value="+226">Burkina Faso (+226)</Option>
        <Option value="+257">Burundi (+257)</Option>
        <Option value="+855">Cambodia (+855)</Option>
        <Option value="+237">Cameroon (+237)</Option>
        <Option value="+1">Canada (+1)</Option>
        <Option value="+238">Cape Verde Islands (+238)</Option>
        <Option value="+1345">Cayman Islands (+1345)</Option>
        <Option value="+236">Central African Republic (+236)</Option>
        <Option value="+56">Chile (+56)</Option>
        <Option value="+86">China (+86)</Option>
        <Option value="+57">Colombia (+57)</Option>
        <Option value="+269">Comoros (+269)</Option>
        <Option value="+242">Congo (+242)</Option>
        <Option value="+682">Cook Islands (+682)</Option>
        <Option value="+506">Costa Rica (+506)</Option>
        <Option value="+385">Croatia (+385)</Option>
        <Option value="+53">Cuba (+53)</Option>
        <Option value="+90392">Cyprus North (+90392)</Option>
        <Option value="+357">Cyprus South (+357)</Option>
        <Option value="+42">Czech Republic (+42)</Option>
        <Option value="+45">Denmark (+45)</Option>
        <Option value="+253">Djibouti (+253)</Option>
        <Option value="+1809">Dominica &amp; Dominican Republic (+1809)</Option>
        <Option value="+593">Ecuador (+593)</Option>
        <Option value="+20">Egypt (+20)</Option>
        <Option value="+503">El Salvador (+503)</Option>
        <Option value="+240">Equatorial Guinea (+240)</Option>
        <Option value="+291">Eritrea (+291)</Option>
        <Option value="+372">Estonia (+372)</Option>
        <Option value="+251">Ethiopia (+251)</Option>
        <Option value="+500">Falkland Islands (+500)</Option>
        <Option value="+298">Faroe Islands (+298)</Option>
        <Option value="+679">Fiji (+679)</Option>
        <Option value="+358">Finland (+358)</Option>
        <Option value="+33">France (+33)</Option>
        <Option value="+594">French Guiana (+594)</Option>
        <Option value="+689">French Polynesia (+689)</Option>
        <Option value="+241">Gabon (+241)</Option>
        <Option value="+220">Gambia (+220)</Option>
        <Option value="+7880">Georgia (+7880)</Option>
        <Option value="+49">Germany (+49)</Option>
        <Option value="+233">Ghana (+233)</Option>
        <Option value="+350">Gibraltar (+350)</Option>
        <Option value="+30">Greece (+30)</Option>
        <Option value="+299">Greenland (+299)</Option>
        <Option value="+1473">Grenada (+1473)</Option>
        <Option value="+590">Guadeloupe (+590)</Option>
        <Option value="+671">Guam (+671)</Option>
        <Option value="+502">Guatemala (+502)</Option>
        <Option value="+224">Guinea (+224)</Option>
        <Option value="+245">Guinea - Bissau (+245)</Option>
        <Option value="+592">Guyana (+592)</Option>
        <Option value="+509">Haiti (+509)</Option>
        <Option value="+504">Honduras (+504)</Option>
        <Option value="+852">Hong Kong (+852)</Option>
        <Option value="+36">Hungary (+36)</Option>
        <Option value="+354">Iceland (+354)</Option>
        <Option value="+91">India (+91)</Option>
        <Option value="+62">Indonesia (+62)</Option>
        <Option value="+98">Iran (+98)</Option>
        <Option value="+964">Iraq (+964)</Option>
        <Option value="+353">Ireland (+353)</Option>
        <Option value="+972">Israel (+972)</Option>
        <Option value="+39">Italy (+39)</Option>
        <Option value="+1876">Jamaica (+1876)</Option>
        <Option value="+81">Japan (+81)</Option>
        <Option value="+962">Jordan (+962)</Option>
        <Option value="+7">Kazakhstan (+7)</Option>
        <Option value="+254">Kenya (+254)</Option>
        <Option value="+686">Kiribati (+686)</Option>
        <Option value="+850">Korea North (+850)</Option>
        <Option value="+82">Korea South (+82)</Option>
        <Option value="+965">Kuwait (+965)</Option>
        <Option value="+996">Kyrgyzstan (+996)</Option>
        <Option value="+856">Laos (+856)</Option>
        <Option value="+371">Latvia (+371)</Option>
        <Option value="+961">Lebanon (+961)</Option>
        <Option value="+266">Lesotho (+266)</Option>
        <Option value="+231">Liberia (+231)</Option>
        <Option value="+218">Libya (+218)</Option>
        <Option value="+417">Liechtenstein (+417)</Option>
        <Option value="+370">Lithuania (+370)</Option>
        <Option value="+352">Luxembourg (+352)</Option>
        <Option value="+853">Macao (+853)</Option>
        <Option value="+389">Macedonia (+389)</Option>
        <Option value="+261">Madagascar (+261)</Option>
        <Option value="+265">Malawi (+265)</Option>
        <Option value="+60">Malaysia (+60)</Option>
        <Option value="+960">Maldives (+960)</Option>
        <Option value="+223">Mali (+223)</Option>
        <Option value="+356">Malta (+356)</Option>
        <Option value="+692">Marshall Islands (+692)</Option>
        <Option value="+596">Martinique (+596)</Option>
        <Option value="+222">Mauritania (+222)</Option>
        <Option value="+269">Mayotte (+269)</Option>
        <Option value="+52">Mexico (+52)</Option>
        <Option value="+691">Micronesia (+691)</Option>
        <Option value="+373">Moldova (+373)</Option>
        <Option value="+377">Monaco (+377)</Option>
        <Option value="+976">Mongolia (+976)</Option>
        <Option value="+1664">Montserrat (+1664)</Option>
        <Option value="+212">Morocco (+212)</Option>
        <Option value="+258">Mozambique (+258)</Option>
        <Option value="+95">Myanmar (+95)</Option>
        <Option value="+264">Namibia (+264)</Option>
        <Option value="+674">Nauru (+674)</Option>
        <Option value="+977">Nepal (+977)</Option>
        <Option value="+31">Netherlands (+31)</Option>
        <Option value="+687">New Caledonia (+687)</Option>
        <Option value="+64">New Zealand (+64)</Option>
        <Option value="+505">Nicaragua (+505)</Option>
        <Option value="+227">Niger (+227)</Option>
        <Option value="+234">Nigeria (+234)</Option>
        <Option value="+683">Niue (+683)</Option>
        <Option value="+672">Norfolk Islands (+672)</Option>
        <Option value="+670">Northern Marianas (+670)</Option>
        <Option value="+47">Norway (+47)</Option>
        <Option value="+968">Oman (+968)</Option>
        <Option value="+680">Palau (+680)</Option>
        <Option value="+507">Panama (+507)</Option>
        <Option value="+675">Papua New Guinea (+675)</Option>
        <Option value="+595">Paraguay (+595)</Option>
        <Option value="+51">Peru (+51)</Option>
        <Option value="+63">Philippines (+63)</Option>
        <Option value="+48">Poland (+48)</Option>
        <Option value="+351">Portugal (+351)</Option>
        <Option value="+1787">Puerto Rico (+1787)</Option>
        <Option value="+974">Qatar (+974)</Option>
        <Option value="+262">Reunion (+262)</Option>
        <Option value="+40">Romania (+40)</Option>
        <Option value="+7">Russia (+7)</Option>
        <Option value="+250">Rwanda (+250)</Option>
        <Option value="+378">San Marino (+378)</Option>
        <Option value="+239">Sao Tome &amp; Principe (+239)</Option>
        <Option value="+966">Saudi Arabia (+966)</Option>
        <Option value="+221">Senegal (+221)</Option>
        <Option value="+381">Serbia (+381)</Option>
        <Option value="+248">Seychelles (+248)</Option>
        <Option value="+232">Sierra Leone (+232)</Option>
        <Option value="+65">Singapore (+65)</Option>
        <Option value="+421">Slovak Republic (+421)</Option>
        <Option value="+386">Slovenia (+386)</Option>
        <Option value="+677">Solomon Islands (+677)</Option>
        <Option value="+252">Somalia (+252)</Option>
        <Option value="+27">South Africa (+27)</Option>
        <Option value="+34">Spain (+34)</Option>
        <Option value="+94">Sri Lanka (+94)</Option>
        <Option value="+290">St. Helena (+290)</Option>
        <Option value="+1869">St. Kitts (+1869)</Option>
        <Option value="+1758">St. Lucia (+1758)</Option>
        <Option value="+249">Sudan (+249)</Option>
        <Option value="+597">Suriname (+597)</Option>
        <Option value="+268">Swaziland (+268)</Option>
        <Option value="+46">Sweden (+46)</Option>
        <Option value="+41">Switzerland (+41)</Option>
        <Option value="+963">Syria (+963)</Option>
        <Option value="+886">Taiwan (+886)</Option>
        <Option value="+7">Tajikstan (+7)</Option>
        <Option value="+66">Thailand (+66)</Option>
        <Option value="+228">Togo (+228)</Option>
        <Option value="+676">Tonga (+676)</Option>
        <Option value="+1868">Trinidad &amp; Tobago (+1868)</Option>
        <Option value="+216">Tunisia (+216)</Option>
        <Option value="+90">Turkey (+90)</Option>
        <Option value="+7">Turkmenistan (+7)</Option>
        <Option value="+993">Turkmenistan (+993)</Option>
        <Option value="+1649">Turks &amp; Caicos Islands (+1649)</Option>
        <Option value="+688">Tuvalu (+688)</Option>
        <Option value="+256">Uganda (+256)</Option>
        <Option value="+44">UK (+44)</Option>
        <Option value="+380">Ukraine (+380)</Option>
        <Option value="+971">United Arab Emirates (+971)</Option>
        <Option value="+598">Uruguay (+598)</Option>
        <Option value="+1">USA (+1)</Option>
        <Option value="+7">Uzbekistan (+7)</Option>
        <Option value="+678">Vanuatu (+678)</Option>
        <Option value="+379">Vatican City (+379)</Option>
        <Option value="+58">Venezuela (+58)</Option>
        <Option value="+84">Vietnam (+84)</Option>
        <Option value="+84">Virgin Islands - British (+1284)</Option>
        <Option value="+84">Virgin Islands - US (+1340)</Option>
        <Option value="+681">Wallis &amp; Futuna (+681)</Option>
        <Option value="+969">Yemen (North)(+969)</Option>
        <Option value="+967">Yemen (South)(+967)</Option>
        <Option value="+260">Zambia (+260)</Option>
        <Option value="+263">Zimbabwe (+263)</Option>
      </Select>
    </Form.Item>
  );


  return (
    <div>
      {loader == false ?
        <Card className="gx-card" title="Add User">
          <Form layout="vertical"
            {...formItemLayout}
            form={form2}
            initialValues={{ country_code: "+91" }}
            onFinish={onSubmit}
          >
            <Form.Item name="id" hidden={true}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Email"
              name="email"
              rules={[{
                required: true,
                message: 'Please enter your email!',
              },
              {
                type: 'email',
                message: 'The enter is not valid E-mail!',
              },
              { validator: validator }
              ]}

            >
              <Input size="large" ref={register} />
            </Form.Item>
            <Form.Item
              label="First Name"
              name="first_name"
              rules={[
                {
                  required: true,
                  message: 'Please enter your First Name!',
                }
              ]}
            >
              <Input size="large" ref={register} />
            </Form.Item>

            <Form.Item
              label="Last Name"
              name="last_name"
              rules={[
                {
                  required: true,
                  message: 'Please enter your last Name!',
                }
              ]}
            >
              <Input size="large" ref={register} />
            </Form.Item>

            <Form.Item
              label="User Name"
              name="user_name"
              rules={[
                { required: true, message: 'Please enter your User Name!' },
                { validator: validator }
              ]}
            >
              <Input size="large" ref={register}
              // onKeyUp={(e) => checkdatabase(e)}
              />
            </Form.Item>

            <Form.Item
              name="phone_number"
              label="Phone Number"
              rules={[{ required: true, message: 'Please enter your phone number!' },
              { validator: validator }
              ]}
            >
              <Input addonBefore={prefixSelector} ref={register} />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              rules={[
                {
                  required: true,
                  message: 'Please enter your password!',
                },
                { validator: validator }
              ]}
              hasFeedback
            >
              <Input.Password ref={register} />
            </Form.Item>

            <Form.Item
              name="confirm"
              label="Confirm Password"
              dependencies={['password']}
              hasFeedback
              rules={[
                {
                  required: true,
                  message: 'Please confirm your password!',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(
                      'The two passwords that you entered do not match!'
                    )
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
              <Button id="addbutton" type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>

          </Form>

        </Card>
        :
        <div className="gx-loader-view">
          <CircularProgress />
        </div>}
    </div>
  );
}

export default AddUser;
