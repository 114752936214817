import React, { useState } from "react";
import { Card, Form, Space, Upload, Button, Modal, message } from "antd";
import { firestore, firebase, storage } from "../../firebase/firebase";
import CircularProgress from "../CircularProgress/index";
import jsZip from "jszip";
import Service from "../../service";
import moment from "moment";
const { REACT_APP_PRODUCTION_MODE } = process.env;

export default function AddExhibitionPhotograph(props) {
  const [fileList, setFileList] = useState([]);
  const [image, setImage] = useState(null);
  const [loader, setLoading] = useState(false);
  const [previewImage, setPreviewImage] = useState(false);
  const [isValidZip, setIsValidZip] = useState(false);
  const [fileData, setFileData] = useState(null);
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 16,
        offset: 8,
      },
    },
  };

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => (resolve(reader.result), setImage(reader.result));
      reader.onerror = (error) => reject(error);
    });
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    // setImage(file.url || file.preview)
    setPreviewImage(true);
  };
  const customUpload = (files, uploadDocId, userId) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (files == null) {
          return resolve(null);
        }
        const metadata = {
          contentType: "image/png",
        };
        const storageRef = storage.ref();
        const uploadTask = storageRef
          .child(
            REACT_APP_PRODUCTION_MODE == "development"
              ? `dev_exhibition-photographs/${userId}/${uploadDocId}/exhibition_photo.png`
              : `exhibition-photographs/${userId}/${uploadDocId}/exhibition_photo.png`
          )
          .putString(`data:image/png;base64,${files}`, "data_url", metadata);
        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                // console.log('Upload is paused');
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                // console.log('Upload is running');
                break;
            }
          },
          (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case "storage/unauthorized":
                // User doesn't have permission to access the object
                break;
              case "storage/canceled":
                // User canceled the upload
                break;

              // ...

              case "storage/unknown":
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref
              .getDownloadURL("exhibition-photographs")
              .then((url) => {
                if (!!url) {
                  resolve(url);
                }
              });
          }
        );
      } catch (e) {
        console.log(e);
        reject(e);
      }
    });
  };

  const addPhotographNotification = async (userData, userId) => {
    try {
      const photographNotification = {
        token: userData?.token,
        type: "0",
        body: "WOW - your artworks look amazing in the exhibitions! Download the pictures of them now!",
        body_spanish:
          "WOW - ¡Sus obras de arte se ven increíbles en las exposiciones! ¡Descárgate ya las fotos!",
        body_german:
          "WOW - deine Kunstwerke sehen fantastisch aus! Die Bilder der Ausstellungen sind jetzt zum Download verfügbar!",
        body_italian:
          "WOW - le tue opere d'arte sono fantastiche! Le foto delle mostre sono ora disponibili per il download!",
        body_french:
          "WOW - vos œuvres d'art ont l'air fantastiques ! Les photos des expositions sont maintenant disponibles au téléchargement !",
        title: "Exhibition image download",
        title_spanish: "Descargar imágenes de la exposición",
        title_german: "Download Ausstellungsbilder",
        title_italian: "Scarica le immagini della mostra",
        title_french: "Télécharger les images de l'exposition",
        sender_user_id: "",
        check_device: "web",
        receiver_user_id: userId,
        post_id: "",
        check_mode: REACT_APP_PRODUCTION_MODE,
      };
      const sendNotification = await Service.makeAPICall({
        methodName: Service.postMethod,
        api_url: Service.sendUserNotification,
        body: photographNotification,
      });
    } catch (error) {
      setLoading(false);
      message.error("Something went to wrong");
    }
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      let usersIds = [];
      let errorShown = false;
      let dateCurrent = moment(new Date()).format("DDMMYYYY")?.toString();
      jsZip.loadAsync(fileData).then(function (zip) {
        Object.keys(zip.files).forEach(async function (filename, index) {
          let checkFileName = filename?.includes("_MACOSX");
          if (!checkFileName) {
              let fileNameParts = filename.split("_");
              if (
                fileNameParts.length !== 4 ||
                !fileNameParts[0] ||
                !fileNameParts[1] ||
                !fileNameParts[2] ||
                !fileNameParts[3]
              ) {
                if (!errorShown) {
                  message.error(
                    "Please upload a zip file containing exhibition photos."
                  );
                  errorShown = true;
                }
                setFileList((prevFileList) =>
                  prevFileList?.filter((file) => file.name !== filename)
                );
                // setFileList([])
                setIsValidZip(false);
                setLoading(false)
                return;
               
              }
             
            await zip.files[filename]
              .async("nodebuffer")
              .then(async function (fileData) {
                let buff = new Buffer(fileData);
                let base64data = buff.toString("base64");
                let fileName = filename.split("_");
                console.log("🚀 ~ fileName:", fileName);
                const docRef = await firestore
                  .collection(
                    REACT_APP_PRODUCTION_MODE == "development"
                      ? "dev_exhibition_photographs"
                      : "exhibition_photographs"
                  )
                  .doc(fileName[0])
                  .get();
                if (!docRef.exists) {
                  const userId =
                    fileName[fileName.length - 1].split(/[\s. ]+/)[0];
                  console.log("🚀 ~ userId:", userId);
                  const data = await customUpload(
                    base64data,
                    fileName[0],
                    userId
                  );
                  const addphotographs = {
                    user_id: userId,
                    img_url: data,
                    status: "approved",
                    year: new Date().getFullYear().toString(),
                    create_at: firebase.firestore.FieldValue.serverTimestamp(),
                    update_at: firebase.firestore.FieldValue.serverTimestamp(),
                  };
                  const userEmailCheck = await firestore
                    .collection(
                      REACT_APP_PRODUCTION_MODE == "development"
                        ? "dev_email_artwork_submit"
                        : "email_artwork_submit"
                    )
                    .doc(`${dateCurrent}-${userId}`)
                    .get();
                  if (!userEmailCheck.exists && !usersIds?.includes(userId)) {
                    console.log("🚀 ~ userEmailCheck:", userEmailCheck);
                    usersIds.push(userId);
                    const userData = await firestore
                      .collection(
                        REACT_APP_PRODUCTION_MODE == "development"
                          ? "dev_users"
                          : "users"
                      )
                      .doc(userId)
                      .get();
                    if (userData.exists) {
                      await firestore
                        .collection(
                          REACT_APP_PRODUCTION_MODE == "development"
                            ? "dev_email_artwork_submit"
                            : "email_artwork_submit"
                        )
                        .doc(`${dateCurrent}-${userId}`)
                        .set({
                          user_id: userId,
                        });
                      let userdata = userData.data();
                      await addPhotographNotification(
                        userdata,
                        userdata?.user_id
                      );
                      await Service.makeAPICall({
                        methodName: Service.postMethod,
                        api_url: Service.exhibitionPhotographEmail,
                        body: {
                          user_id: userId,
                          check_mode: REACT_APP_PRODUCTION_MODE,
                        },
                      });
                    }
                  }
                  // write to Cloud Firestore
                  await firestore
                    .collection(
                      REACT_APP_PRODUCTION_MODE == "development"
                        ? "dev_exhibition_photographs"
                        : "exhibition_photographs"
                    )
                    .doc(fileName[0])
                    .set(addphotographs);
                  setLoading(false);
                  console.log("first");
                }
              });
          }
          if (Object.keys(zip.files)?.length - 1 == index) {
            console.log("second");

            setFileList([]);
            setFileData(null);
            console.log(props.history, "props.history");

            return props.history.push({
              pathname: "/exhibition-photography-list",
            });
          }
        });
      });
      // message.success("Successfully Upload");
    } catch (error) {
      message.error("Please select only zip file");
    }
  };
  // const handleChange = async (info) => {
  //   if (info.file.status === "uploading") {
  //     info.file.status = "done";
  //   }
  //   if (info.file.status === "done") {
  //     if (info.file.type !== 'application/zip') {
  //       message.error("Please select only zip file");
  //       setFileList(fileList.filter(file => file.uid !== info.file.uid));
  //       return;
  //     }
  //     setFileList(info.fileList);
  //   }
  // };

  const handleChange = async (info) => {
    const { file, fileList } = info;
    if (file.status === "removed") {
      setFileList(fileList.filter((item) => item.uid !== file.uid));
      setIsValidZip(false);
    }
     if (info.file.status === "uploading") {
      info.file.status = "done";
    }
    if (info.file.status === "done") {
      if (info.file.type !== "application/zip") {
        message.error("Please select only zip file");
        setFileList(fileList?.filter((file) => file.uid !== info.file.uid));
        setIsValidZip(false);
        return;
      }
      const zip = await jsZip.loadAsync(info.file.originFileObj);
      let hasValidFile = false;
      zip.forEach((relativePath, file) => {
        if (!file.dir && !file.name?.includes("/")) {
          if (file.name.match(/\.(jpg|jpeg|png)$/i)) {
            hasValidFile = true;
          }
        }
      });
      if (!hasValidFile) {
        message.error(
          "Please select a zip file containing images in the root directory"
        );
        setFileList(fileList.filter((file) => file.uid !== info.file.uid));
        setIsValidZip(false);
        return;
      }
      setIsValidZip(true);
      setFileList(info.fileList);
    }
  };

  const uploaderProps = {
    name: "file",
    listType: "picture-card",
    accept: "application/zip",
    multiple: true,
    maxCount: 10,
    fileList: fileList,
    onPreview: handlePreview,
    onChange: handleChange,
    customRequest: async ({ onSuccess, file }) => {
      setFileData(file);
      onSuccess("ok");
    },
    onRemove: (file) => {},
  };
  const uploadButton = (
    <Upload {...uploaderProps}>
      {fileList.length < uploaderProps.maxCount && "+ Upload"}
    </Upload>
  );

  return (
    <Card title="Add Exhibition Photograph">
      {loader == false ? (
        <>
          <Form layout="vertical" {...formItemLayout} onFinish={onFinish}>
            <Form.Item
              label="Add Photograph"
              name="img_url"
              rules={[
                {
                  required: true,
                  message: "Please select only zip file",
                },
              ]}
            >
              <Space direction="vertical" size={12}>
                {uploadButton}
                <span>Please only zip file upload</span>
              </Space>
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <Button
                id="addbutton"
                type="primary"
                htmlType="submit"
                disabled={!isValidZip}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
          <Modal
            visible={previewImage}
            footer={null}
            onCancel={() => setPreviewImage(false)}
          >
            <img alt="example" style={{ width: "100%" }} src={image} />
          </Modal>
        </>
      ) : (
        <div className="gx-loader-view">
          <CircularProgress />
        </div>
      )}
    </Card>
  );
}
