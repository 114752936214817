import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Select,
  Button,
  Card,
  Space, message, Upload, Modal
} from 'antd';
import { useSelector } from "react-redux";
import { firestore, firebase, storage } from '../../firebase/firebase';
import CircularProgress from "../CircularProgress/index";
const { REACT_APP_PRODUCTION_MODE } = process.env;

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const AdminProfile = (props) => {
  const [userDocument, setUserDocument] = useState(null);
  const [loader, setLoading] = useState(false);
  const [form] = Form.useForm();
  const { authUser } = useSelector(({ auth }) => auth);
  const [file, setFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(false);
  const [image, setImage] = useState(false);

  useEffect(() => {
    setLoading(true)
    firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_admin' : 'admin').doc(localStorage.getItem('user_id'))
      .onSnapshot((doc) => {
        if (doc.exists) {
          const documentData = doc.data();
          setUserDocument(documentData);
        }
      });
    setLoading(false)
  }, []);

  const validator = async (rule, value) => {
    if (rule.field === 'email' && value) {
      const querySnapshot = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_admin' : 'admin')
        .where("email", '!=', userDocument?.email)
        .where("email", '==', value)
        .get()

      if (!querySnapshot.empty) {
        return Promise.reject('email already exists ');
      }
      else {
        return Promise.resolve();
      }
    }
    else if (rule.field === 'phone_number') {
      if (value && ! /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(value)) {
        return Promise.reject('Please enter only number');
      } else {
        return Promise.resolve();
      }
    }
    else if (rule.field === 'phone_number' && value) {
      const querySnapshot = await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_admin' : 'admin')
        .where("phone_number", '!=', userDocument?.phone_number)
        .where("phone_number", '==', value)
        .get()

      if (!querySnapshot.empty) {
        return Promise.reject('phone number already exists');
      } else {
        return Promise.resolve();
      }
    }
  }

  const onFinish = async (data) => {
    try {
      setLoading(true)
      const url = await customUpload();
      data.profile_url = url == null ? userDocument?.profile_url : url
      await firestore.collection(REACT_APP_PRODUCTION_MODE == 'development' ? 'dev_admin' : "admin").doc(localStorage.getItem('user_id')).update({ uid: localStorage.getItem('user_id'), ...data });
      setLoading(false)
      message.success('Successfully Updated');
      props.history.push({
        pathname: `/adminprofile`,
      });
    } catch (error) {
      setLoading(false)
      message.error('Something went to wrong');
    }
  };



  // function beforeUpload(file) {
  //   const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  //   if (!isJpgOrPng) {
  //     message.error('You can only upload JPG/PNG file!');
  //   }
  //   const isLt2M = file.size / 1024 / 1024 < 5;
  //   if (!isLt2M) {

  //     message.error('Image must smaller than 5MB!');
  //   }
  //   return isJpgOrPng && isLt2M;
  // }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => (resolve(reader.result), setImage(reader.result));
      reader.onerror = error => reject(error);
    });
  }

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    // setImage(file.url || file.preview) 
    setPreviewImage(true)

  }

  const handleChange = (info) => {

  }

  const customUpload = () => {
    return (new Promise(async (resolve, reject) => {
      try {
        if (file == null) {
          return resolve(null);
        }
        const metadata = {
          contentType: 'image/jpeg'
        }
        //  const file = fileList==''?userDocument?.profile_url:fileList
        const storageRef = storage.ref();
        const uploadTask = storageRef.child(REACT_APP_PRODUCTION_MODE == 'development' ? `dev_admin/${authUser}/profile-image` : `admin/${authUser}/profile-image`).put(file, metadata);
        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            // var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            // console.log('Upload is ' + progress + '% done');
            switch (snapshot.state) {
              case firebase.storage.TaskState.PAUSED: // or 'paused'
                // console.log('Upload is paused');
                break;
              case firebase.storage.TaskState.RUNNING: // or 'running'
                // console.log('Upload is running');
                break;
            }
          },
          (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                break;
              case 'storage/canceled':
                // User canceled the upload
                break;

              // ...

              case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                break;
            }
          },
          () => {
            // Upload completed successfully, now we can get the download URL
            uploadTask.snapshot.ref.getDownloadURL(authUser).then((url) => {
              if (!!url) {
                resolve(url);
              }
            })
          }
        );

      } catch (e) {
        reject(e);
      }
    }))
  };


  // const getDownloadUrl = async () => {
  //   try {
  //     return await storage.ref().child(`admin/${authUser}/profile-image`).getDownloadURL();
  //   } catch (e) {
  //     message.error('Something went to wrong');
  //   }
  // };

  // useEffect(() => {
  //   setLoading(true)
  //   getDownloadUrl(authUser).then((url) => !!url && setImageUrl(url));
  // }, [authUser]);

  function onSearch() {

  }
  const prefixSelector = (
    <Form.Item name="country_code" noStyle>
      <Select showSearch placeholder="Select a Country Code" style={{ width: 200 }} onSearch={onSearch} filterOption={(input, option) =>
        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }>
        <Option value="+213">Algeria (+213)</Option>
        <Option value="+376">Andorra (+376)</Option>
        <Option value="+244">Angola (+244)</Option>
        <Option value="+1264">Anguilla (+1264)</Option>
        <Option value="+1268">Antigua &amp; Barbuda (+1268)</Option>
        <Option value="+54">Argentina (+54)</Option>
        <Option value="+374">Armenia (+374)</Option>
        <Option value="+297">Aruba (+297)</Option>
        <Option value="+61">Australia (+61)</Option>
        <Option value="+43">Austria (+43)</Option>
        <Option value="+994">Azerbaijan (+994)</Option>
        <Option value="+1242">Bahamas (+1242)</Option>
        <Option value="+973">Bahrain (+973)</Option>
        <Option value="+880">Bangladesh (+880)</Option>
        <Option value="+1246">Barbados (+1246)</Option>
        <Option value="+375">Belarus (+375)</Option>
        <Option value="+32">Belgium (+32)</Option>
        <Option value="+501">Belize (+501)</Option>
        <Option value="+229">Benin (+229)</Option>
        <Option value="+1441">Bermuda (+1441)</Option>
        <Option value="+975">Bhutan (+975)</Option>
        <Option value="+591">Bolivia (+591)</Option>
        <Option value="+387">Bosnia Herzegovina (+387)</Option>
        <Option value="+267">Botswana (+267)</Option>
        <Option value="+55">Brazil (+55)</Option>
        <Option value="+673">Brunei (+673)</Option>
        <Option value="+359">Bulgaria (+359)</Option>
        <Option value="+226">Burkina Faso (+226)</Option>
        <Option value="+257">Burundi (+257)</Option>
        <Option value="+855">Cambodia (+855)</Option>
        <Option value="+237">Cameroon (+237)</Option>
        <Option value="+1">Canada (+1)</Option>
        <Option value="+238">Cape Verde Islands (+238)</Option>
        <Option value="+1345">Cayman Islands (+1345)</Option>
        <Option value="+236">Central African Republic (+236)</Option>
        <Option value="+56">Chile (+56)</Option>
        <Option value="+86">China (+86)</Option>
        <Option value="+57">Colombia (+57)</Option>
        <Option value="+269">Comoros (+269)</Option>
        <Option value="+242">Congo (+242)</Option>
        <Option value="+682">Cook Islands (+682)</Option>
        <Option value="+506">Costa Rica (+506)</Option>
        <Option value="+385">Croatia (+385)</Option>
        <Option value="+53">Cuba (+53)</Option>
        <Option value="+90392">Cyprus North (+90392)</Option>
        <Option value="+357">Cyprus South (+357)</Option>
        <Option value="+42">Czech Republic (+42)</Option>
        <Option value="+45">Denmark (+45)</Option>
        <Option value="+253">Djibouti (+253)</Option>
        <Option value="+1809">Dominica &amp; Dominican Republic (+1809)</Option>
        <Option value="+593">Ecuador (+593)</Option>
        <Option value="+20">Egypt (+20)</Option>
        <Option value="+503">El Salvador (+503)</Option>
        <Option value="+240">Equatorial Guinea (+240)</Option>
        <Option value="+291">Eritrea (+291)</Option>
        <Option value="+372">Estonia (+372)</Option>
        <Option value="+251">Ethiopia (+251)</Option>
        <Option value="+500">Falkland Islands (+500)</Option>
        <Option value="+298">Faroe Islands (+298)</Option>
        <Option value="+679">Fiji (+679)</Option>
        <Option value="+358">Finland (+358)</Option>
        <Option value="+33">France (+33)</Option>
        <Option value="+594">French Guiana (+594)</Option>
        <Option value="+689">French Polynesia (+689)</Option>
        <Option value="+241">Gabon (+241)</Option>
        <Option value="+220">Gambia (+220)</Option>
        <Option value="+7880">Georgia (+7880)</Option>
        <Option value="+49">Germany (+49)</Option>
        <Option value="+233">Ghana (+233)</Option>
        <Option value="+350">Gibraltar (+350)</Option>
        <Option value="+30">Greece (+30)</Option>
        <Option value="+299">Greenland (+299)</Option>
        <Option value="+1473">Grenada (+1473)</Option>
        <Option value="+590">Guadeloupe (+590)</Option>
        <Option value="+671">Guam (+671)</Option>
        <Option value="+502">Guatemala (+502)</Option>
        <Option value="+224">Guinea (+224)</Option>
        <Option value="+245">Guinea - Bissau (+245)</Option>
        <Option value="+592">Guyana (+592)</Option>
        <Option value="+509">Haiti (+509)</Option>
        <Option value="+504">Honduras (+504)</Option>
        <Option value="+852">Hong Kong (+852)</Option>
        <Option value="+36">Hungary (+36)</Option>
        <Option value="+354">Iceland (+354)</Option>
        <Option value="+91">India (+91)</Option>
        <Option value="+62">Indonesia (+62)</Option>
        <Option value="+98">Iran (+98)</Option>
        <Option value="+964">Iraq (+964)</Option>
        <Option value="+353">Ireland (+353)</Option>
        <Option value="+972">Israel (+972)</Option>
        <Option value="+39">Italy (+39)</Option>
        <Option value="+1876">Jamaica (+1876)</Option>
        <Option value="+81">Japan (+81)</Option>
        <Option value="+962">Jordan (+962)</Option>
        <Option value="+7">Kazakhstan (+7)</Option>
        <Option value="+254">Kenya (+254)</Option>
        <Option value="+686">Kiribati (+686)</Option>
        <Option value="+850">Korea North (+850)</Option>
        <Option value="+82">Korea South (+82)</Option>
        <Option value="+383">Kosovo (+383)</Option>
        <Option value="+965">Kuwait (+965)</Option>
        <Option value="+996">Kyrgyzstan (+996)</Option>
        <Option value="+856">Laos (+856)</Option>
        <Option value="+371">Latvia (+371)</Option>
        <Option value="+961">Lebanon (+961)</Option>
        <Option value="+266">Lesotho (+266)</Option>
        <Option value="+231">Liberia (+231)</Option>
        <Option value="+218">Libya (+218)</Option>
        <Option value="+417">Liechtenstein (+417)</Option>
        <Option value="+370">Lithuania (+370)</Option>
        <Option value="+352">Luxembourg (+352)</Option>
        <Option value="+853">Macao (+853)</Option>
        <Option value="+389">Macedonia (+389)</Option>
        <Option value="+261">Madagascar (+261)</Option>
        <Option value="+265">Malawi (+265)</Option>
        <Option value="+60">Malaysia (+60)</Option>
        <Option value="+960">Maldives (+960)</Option>
        <Option value="+223">Mali (+223)</Option>
        <Option value="+356">Malta (+356)</Option>
        <Option value="+692">Marshall Islands (+692)</Option>
        <Option value="+596">Martinique (+596)</Option>
        <Option value="+222">Mauritania (+222)</Option>
        <Option value="+269">Mayotte (+269)</Option>
        <Option value="+52">Mexico (+52)</Option>
        <Option value="+691">Micronesia (+691)</Option>
        <Option value="+373">Moldova (+373)</Option>
        <Option value="+377">Monaco (+377)</Option>
        <Option value="+976">Mongolia (+976)</Option>
        <Option value="+1664">Montserrat (+1664)</Option>
        <Option value="+212">Morocco (+212)</Option>
        <Option value="+258">Mozambique (+258)</Option>
        <Option value="+95">Myanmar (+95)</Option>
        <Option value="+264">Namibia (+264)</Option>
        <Option value="+674">Nauru (+674)</Option>
        <Option value="+977">Nepal (+977)</Option>
        <Option value="+31">Netherlands (+31)</Option>
        <Option value="+687">New Caledonia (+687)</Option>
        <Option value="+64">New Zealand (+64)</Option>
        <Option value="+505">Nicaragua (+505)</Option>
        <Option value="+227">Niger (+227)</Option>
        <Option value="+234">Nigeria (+234)</Option>
        <Option value="+683">Niue (+683)</Option>
        <Option value="+672">Norfolk Islands (+672)</Option>
        <Option value="+670">Northern Marianas (+670)</Option>
        <Option value="+47">Norway (+47)</Option>
        <Option value="+968">Oman (+968)</Option>
        <Option value="+680">Palau (+680)</Option>
        <Option value="+507">Panama (+507)</Option>
        <Option value="+675">Papua New Guinea (+675)</Option>
        <Option value="+595">Paraguay (+595)</Option>
        <Option value="+51">Peru (+51)</Option>
        <Option value="+63">Philippines (+63)</Option>
        <Option value="+48">Poland (+48)</Option>
        <Option value="+351">Portugal (+351)</Option>
        <Option value="+1787">Puerto Rico (+1787)</Option>
        <Option value="+974">Qatar (+974)</Option>
        <Option value="+262">Reunion (+262)</Option>
        <Option value="+40">Romania (+40)</Option>
        <Option value="+7">Russia (+7)</Option>
        <Option value="+250">Rwanda (+250)</Option>
        <Option value="+378">San Marino (+378)</Option>
        <Option value="+239">Sao Tome &amp; Principe (+239)</Option>
        <Option value="+966">Saudi Arabia (+966)</Option>
        <Option value="+221">Senegal (+221)</Option>
        <Option value="+381">Serbia (+381)</Option>
        <Option value="+248">Seychelles (+248)</Option>
        <Option value="+232">Sierra Leone (+232)</Option>
        <Option value="+65">Singapore (+65)</Option>
        <Option value="+421">Slovak Republic (+421)</Option>
        <Option value="+386">Slovenia (+386)</Option>
        <Option value="+677">Solomon Islands (+677)</Option>
        <Option value="+252">Somalia (+252)</Option>
        <Option value="+27">South Africa (+27)</Option>
        <Option value="+34">Spain (+34)</Option>
        <Option value="+94">Sri Lanka (+94)</Option>
        <Option value="+290">St. Helena (+290)</Option>
        <Option value="+1869">St. Kitts (+1869)</Option>
        <Option value="+1758">St. Lucia (+1758)</Option>
        <Option value="+249">Sudan (+249)</Option>
        <Option value="+597">Suriname (+597)</Option>
        <Option value="+268">Swaziland (+268)</Option>
        <Option value="+46">Sweden (+46)</Option>
        <Option value="+41">Switzerland (+41)</Option>
        <Option value="+963">Syria (+963)</Option>
        <Option value="+886">Taiwan (+886)</Option>
        <Option value="+7">Tajikstan (+7)</Option>
        <Option value="+66">Thailand (+66)</Option>
        <Option value="+228">Togo (+228)</Option>
        <Option value="+676">Tonga (+676)</Option>
        <Option value="+1868">Trinidad &amp; Tobago (+1868)</Option>
        <Option value="+216">Tunisia (+216)</Option>
        <Option value="+90">Turkey (+90)</Option>
        <Option value="+7">Turkmenistan (+7)</Option>
        <Option value="+993">Turkmenistan (+993)</Option>
        <Option value="+1649">Turks &amp; Caicos Islands (+1649)</Option>
        <Option value="+688">Tuvalu (+688)</Option>
        <Option value="+256">Uganda (+256)</Option>
        <Option value="+44">UK (+44)</Option>
        <Option value="+380">Ukraine (+380)</Option>
        <Option value="+971">United Arab Emirates (+971)</Option>
        <Option value="+598">Uruguay (+598)</Option>
        <Option value="+1">USA (+1)</Option>
        <Option value="+7">Uzbekistan (+7)</Option>
        <Option value="+678">Vanuatu (+678)</Option>
        <Option value="+379">Vatican City (+379)</Option>
        <Option value="+58">Venezuela (+58)</Option>
        <Option value="+84">Vietnam (+84)</Option>
        <Option value="+84">Virgin Islands - British (+1284)</Option>
        <Option value="+84">Virgin Islands - US (+1340)</Option>
        <Option value="+681">Wallis &amp; Futuna (+681)</Option>
        <Option value="+969">Yemen (North)(+969)</Option>
        <Option value="+967">Yemen (South)(+967)</Option>
        <Option value="+260">Zambia (+260)</Option>
        <Option value="+263">Zimbabwe (+263)</Option>
      </Select>
    </Form.Item>
  );




  if (!userDocument) {
    return (<> </>);
  }

  return (
    <>
      {loader == false ?
        <Card className="gx-card" title="Profile Details">
          <Form layout="vertical"
            {...formItemLayout}
            form={form}
            name="register"
            onFinish={onFinish}
            initialValues={{
              ...userDocument,
              // country_code: '+91',
            }}
          >

            <Form.Item
              label="User Profile"
              name="profile_url"
            >
              <Space direction="vertical" size={12}>
                <Upload
                  name="profile_url"
                  listType='picture-card'
                  maxCount={1}
                  onRemove={() => setFile(null)}
                  onPreview={handlePreview}
                  onChange={handleChange}
                  customRequest={({ onSuccess, file }) =>
                  (onSuccess('ok'),
                    setFile(file))}
                >
                  {file == null ? <img alt="example" style={{ width: '100%' }} src={userDocument?.profile_url} /> : null}
                </Upload>
              </Space>

            </Form.Item>

            <Form.Item
              name="full_name"
              label="Full Name"
              rules={[
                {
                  required: true,
                  message: 'Please enter your Full Name!',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="email"
              label="E-mail"
              rules={[
                {
                  type: 'email',
                  message: 'your email is not valid!',
                },
                {
                  required: true,
                  message: 'Please enter your email!',
                },
                { validator: validator }
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="phone_number"
              label="Phone Number"
              rules={[{ required: true, message: 'Please enter your phone number!' },
              { validator: validator }
              ]}
            >
              <Input addonBefore={prefixSelector} style={{ width: '100%' }} />
            </Form.Item>

            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
          <Modal
            visible={previewImage}
            footer={null}
            onCancel={() => setPreviewImage(false)}
          >
            <img alt="example" style={{ width: '100%' }} src={image} />
          </Modal>
        </Card>
        :
        <div className="gx-loader-view">
          <CircularProgress />
        </div>}
    </>
  );
};

export default AdminProfile;

